import { v4 as uuidv4 } from 'uuid'
import { getBaseAdditionalDetailState } from '~/shared/fnolAdditionalQuestions'

export default () => ({
  grpcUuid: uuidv4(),
  contacts: [],
  selectedReporter: null,
  reporterOptsIntoEmail: null,
  reporterOptsIntoText: null,
  notes: [],
  dateOfLoss: '',
  dateOfNotification: new Date(),
  notificationMethod: 'phone',
  descriptionOfLoss: '',
  causeOfDamage: null,
  damageType: null,
  doesKnowSourceOfDamage: null,
  sourceOfDamage: null,
  propertyDamage: {
    damages: [],
    anyUnfinishedDamagedRooms: null,
    hasExteriorDamage: null,
    numberRoomsDamaged: null,
    numberStructuresDamaged: null,
    numberItemsDamaged: null,
    descriptionOfDamagedProperty: '',
  },
  additional: getBaseAdditionalDetailState(),
  attachments: [],
  contactsTabHasErrors: true,
  damageTabHasErrors: true,
  hasVisitedDamageTab: false,
  hasAttemptedReviewScreen: false,
  claimNumber: '',
  claimReferenceNumber: '',
})
