//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { KudoButton } from '@openly-engineering/kudo'

export default {
  components: {
    KudoButton,
  },
}
