export default ($auth, $bugsnag) => {
  return new Promise((resolve) => {
    $auth.checkSession({}, (error, authPayload) => {
      if (error) {
        if (error.code === 'login_required') {
          return resolve(false)
        }

        $bugsnag.notify(error)

        return resolve(false)
      }

      return resolve(authPayload.accessToken)
    })
  })
}
