import hasValidAccessToken from './utilities/hasValidAccessToken'
import getAccessToken from './utilities/getAccessToken'
import saveDestinationURL from './utilities/saveDestinationURL'
import redirectToDestinationURL from './utilities/redirectToDestinationURL'
import silentlyAuthenticateWithAuth0 from './utilities/silentlyAuthenticateWithAuth0'

export default async function(context) {
  const {
    $apolloHelpers,
    app,
    $auth,
    $config,
    $bugsnag,
    store,
  } = context

  const router = app.router

  try {
    const accessToken = $apolloHelpers.getToken()

    if (hasValidAccessToken(accessToken)) {
      await store.dispatch('self/requestSelf')
      return
    }

    // This determines if the user entered the app from the Auth0 login page by checking if the current route contains the Auth0 hash
    if (router?.history?.pending?.hash) {
      const accessToken = await getAccessToken($auth)

      // Sets an apollo-token cookie containing the Auth0 access token that is used for authentication
      await $apolloHelpers.onLogin(accessToken)

      redirectToDestinationURL(router)

      return
    }

    const silentAccessToken = await silentlyAuthenticateWithAuth0($auth, $bugsnag)

    if (silentAccessToken) {
      // Sets an apollo-token cookie containing the Auth0 access token that is used for authentication
      await $apolloHelpers.onLogin(silentAccessToken)

      await store.dispatch('self/requestSelf')
      return
    }

    saveDestinationURL(router)

    // Send user to Auth0 login page
    $auth.authorize({})
  } catch (error) {
    $bugsnag.notify(error)
    location.replace(`${$config.legacyPortalDomain}/agents/dashboard`)
  }
}
