import Vue from 'vue'

Vue.filter('vuelidateErrorsToStrings', (errors) => {
  if (!errors?.length) {
    return []
  }
  return errors.map((error) => {
    return error.$message
  })
})
