import jwtDecode from 'jwt-decode'
import SELF from '~/graphql/queries/self.gql'

const getDefaultState = () => ({
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  states: {},
  isAuthenticated: false,
  isUsingSSO: false,
  avatarUrl: '', // Comes from Auth0
  permissions: {},
  roles: {},
  timeOfLastRequest: null,
  agency: {},
})

export const state = () => getDefaultState()

export const mutations = {
  setSelf(state, self) {
    const wasNotAuthenticated = !state.isAuthenticated

    if (wasNotAuthenticated) {
      this.$bugsnag.setUser(self.user.id)

      this.$bugsnag.addMetadata('user', {
        name: `${self.user.firstName} ${self.user.lastName}`,
        email: self.user.email,
        roles: self.user.roles,
      })

      this.$chat.setupChat(self.user, self.zendeskChatJWT)
      this.$segment.initBaseEvents(self.user)
      state.isAuthenticated = true
    }

    if (!state.avatarUrl) {
      const accessToken = this.$apolloHelpers.getToken()

      const decodedToken = jwtDecode(accessToken)
      state.avatarUrl = decodedToken['https://openly.com/avatar']
    }

    state.id = self.user.id
    state.firstName = self.user.firstName
    state.lastName = self.user.lastName
    state.email = self.user.email
    state.agency = self.user.agency
    state.isUsingSSO = self.user.isOpenly // add -> || self.user.isUserSSO(or whatever the flag is called) when BE updates

    let permissions
    if (Array.isArray(self.permissions)) {
      permissions = {}
      self.permissions.forEach(permissionID => (permissions[permissionID] = true))
    } else {
      permissions = self.permissions
    }
    state.permissions = permissions

    let roles
    if (Array.isArray(self.user.roles)) {
      roles = {}
      self.user.roles.forEach(roleID => (roles[roleID] = true))
    } else {
      roles = self.user.roles
    }
    state.roles = roles

    let states
    if (Array.isArray(self.user.states)) {
      states = {}
      self.user.states.forEach(stateID => (states[stateID] = true))
    } else {
      states = self.user.states
    }
    state.states = states
  },
  setTimeOfLastRequest(state) {
    state.timeOfLastRequest = new Date()
  },
  clearSelf(state) {
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  async requestSelf({ commit }) {
    // If the time since the last network `self` request is less than 15 minutes then use the cached `self`.
    if (this.state.self.timeOfLastRequest && (new Date() - this.state.self.timeOfLastRequest) / 1000 / 60 < 15) {
      const { data } = await this.$apollo.query({
        query: SELF,
      })

      commit('setSelf', data.self)
      return
    }

    // If the time since the last network `self` request is greater than 15 minutes then make a `cache-and-network` `self` request.
    // This ensures that we will eventually get a fresh instance of `self` while maintaining the apollo cache of `self` for consumers that rely upon this reactivity.

    // We create a new promise to wrap the observable returned by `$apollo.watchQuery()` so that we can await the work performed in the subscription of `selfObservable`.
    await new Promise((resolve, reject) => {
      // Note: `$apollo.watchQuery()` returns an observable, unlike `$apollo.query()` which returns a promise.
      // This is because `$apollo.watchQuery()` can have multiple asynchronous results that can be received and handled independently.
      const selfObservable = this.$apollo.watchQuery({
        query: SELF,
        fetchPolicy: 'cache-and-network',
      })

      const subscription = selfObservable.subscribe({
        next: ({ data, networkStatus }) => {
          // Network Status 7 is `ready`.
          // Network Status 8 is `error`.
          // Unsubscribe from the observable stream once we encounter a `ready` or `error` response.
          if (networkStatus === 7 || networkStatus === 8) {
            subscription.unsubscribe()
          }

          // In the case of Network Status 7 we know that this instance of `data` came from the network and not from the apollo cache.
          // Meaning we know it is a fresh copy of `self` and will proceed with it.
          if (networkStatus === 7) {
            commit('setTimeOfLastRequest')
            commit('setSelf', data.self)

            resolve()
          }
        },
        error: err => reject(err),
      })
    })
  },
  async logout({ commit }) {
    await this.$apolloHelpers.onLogout('apollo-token', true)

    commit('clearSelf')

    this.$auth.logout({
      returnTo: `${this.$config.legacyPortalDomain}/agents/authenticate`,
    })
  },
}
