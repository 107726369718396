//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  name: 'ModalErrorController',
  computed: mapState({
    currentError: state => state.error.queue[0],
  }),
}
