import validators from './error-validators'

export default (errors, nuxt) => {
  const { $bugsnag, $config, params, store } = nuxt

  for (const validator of validators) {
    const result = validator(errors, $config, params)

    if (!result.valid) { continue }

    if (result.redirect) {
      return nuxt.error(result.errorContext)
    }

    return store.commit('error/displayErrorModal', result.errorContext)
  }

  // We haven't caught the error in a validator, log the unknown issue to Bugsnag
  $bugsnag.notify(new Error(`An unhandled error occurred:\n\n${errors}`))
  store.commit('error/displayErrorModal')
}
