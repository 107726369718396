export default (ctx, inject) => {
  const segment = {
    initBaseEvents: (user) => {
      if (window.analytics) {
        window.analytics.identify(user.id, {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          roles: user.roles,
          states: user.states,
        })

        const agency = user.agency

        window.analytics.group(agency.id, {
          shortID: agency.shortID,
          name: agency.name,
          email: agency.notificationEmail,
          phone: agency.phone,
          streetLine1: agency.streetLine1,
          streetLine2: agency.streetLine2,
          city: agency.city,
          state: agency.state,
          zip: agency.zip,
          appointedStates: agency.appointedStates,
        })
      }
    },
    sendPageEvent: () => {
      // this grabs the current routes search, window.location.search grabs previous search state
      const searchVal = ctx.route.fullPath.split('?')[1]

      // title currently populates with previous routes data, for now we can leave as an empty string
      window.analytics.page({
        path: ctx.route.path,
        referrer: `${window.location.origin}${ctx.from.fullPath}`,
        search: searchVal ? `?${searchVal}` : '',
        title: '',
        url: `${window.location.origin}${ctx.route.fullPath}`,
      })
    },
  }

  inject('segment', segment)
}
