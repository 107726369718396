//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { KudoButton } from '@openly-engineering/kudo'

export default {
  name: 'Error',
  components: {
    KudoButton,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  computed: {
    buttonLink() {
      return this.error.buttonUrl ? this.error.buttonUrl : `${this.$config.legacyPortalDomain}/agents/dashboard`
    },
    buttonText() {
      return this.error.buttonText ? this.error.buttonText : 'Return to Dashboard'
    },
  },
}
