import { v4 as uuidv4 } from 'uuid'
import { getBaseAdditionalDetailState } from '~/shared/fnolAdditionalQuestions'

export default {
  populateInitialContacts(state, payload) {
    const humanNamedInsureds = payload.data.policy.todaysActiveTransaction.humanNamedInsureds
    const legalEntityInsureds = payload.data.policy.todaysActiveTransaction.legalEntityNamedInsureds
    const agent = payload.data.policy.agent
    const agentPhone = payload.data.policy.agency.phone
    const communicationPref = payload.data.policy.communicationPreferences
    const buildAddress = (insured) => {
      return {
        streetLineOne: insured.streetLine1,
        streetLineTwo: insured.streetLine2,
        city: insured.city,
        region: insured.state,
        postalCode: insured.zip,
      }
    }
    const initialContactBase = {
      isInitialContact: true,
      isReporter: false,
      wasEdited: false,
    }

    if (state.contacts.length === 0) {
      if (humanNamedInsureds) {
        humanNamedInsureds.forEach((insured) => {
          state.contacts.push({
            type: 'person',
            firstName: insured.firstName,
            middleName: insured.middleName || '',
            lastName: insured.lastName,
            relationToInsured: 'insured',
            address: buildAddress(insured),
            id: `contact${state.contacts.length + 1}`,
            phone: insured.isPrimaryContact ? communicationPref.phone : '',
            email: insured.isPrimaryContact ? communicationPref.email : '',
            ...initialContactBase,
          })
        })
      }

      if (legalEntityInsureds) {
        legalEntityInsureds.forEach((insured) => {
          state.contacts.push({
            type: 'organization',
            organizationName: insured.name,
            relationToInsured: 'insured',
            address: buildAddress(insured),
            id: `contact${state.contacts.length + 1}`,
            phone: insured.isPrimaryContact ? communicationPref.phone : '',
            email: insured.isPrimaryContact ? communicationPref.email : '',
            ...initialContactBase,
          })
        })
      }

      if (agent) {
        state.contacts.push({
          type: 'person',
          firstName: agent.firstName,
          middleName: '',
          lastName: agent.lastName,
          id: `contact${state.contacts.length + 1}`,
          relationToInsured: 'insuredAgent',
          email: agent.email,
          address: {},
          phone: agentPhone,
          ...initialContactBase,
        })
      }
    }
  },
  addContact(state, contact) {
    state.contacts.push(contact)
  },
  updateContact(state, payload) {
    const contactToUpdate = state.contacts.find((contact) => {
      return contact.id === payload.id
    })

    contactToUpdate.firstName = payload.firstName
    contactToUpdate.middleName = payload.middleName
    contactToUpdate.lastName = payload.lastName
    contactToUpdate.type = payload.type
    contactToUpdate.organizationName = payload.organizationName
    contactToUpdate.relationToInsured = payload.relationToInsured
    contactToUpdate.email = payload.email
    contactToUpdate.phone = payload.phone
    contactToUpdate.address = payload.address
    contactToUpdate.dateOfBirth = payload.dateOfBirth
    contactToUpdate.wasEdited = payload.wasEdited
  },
  resetContacts(state) {
    state.contacts = []
  },
  removeContact(state, id) {
    state.contacts = state.contacts.filter((contact) => {
      return contact.id !== id
    })
    if (id === state.selectedReporter) {
      state.selectedReporter = null
    }
  },
  updateReporterOnContacts(state, id) {
    state.contacts.forEach((contact) => {
      contact.isReporter = false
    })
    const contactToUpdate = state.contacts.find((contact) => {
      return contact.id === id
    })

    contactToUpdate.isReporter = true
  },
  setSelectedReporter(state, id) {
    state.selectedReporter = id
  },
  resetNotes(state) {
    state.notes = []
  },
  addNewNote(state, note) {
    state.notes.push(note)
  },
  removeNote(state, id) {
    state.notes = state.notes.filter((note) => {
      return note.id !== id
    })
  },
  updateNote(state, payload) {
    const noteToUpdate = state.notes.find((note) => {
      return note.id === payload.id
    })

    noteToUpdate.message = payload.message
  },
  resetAdditionaQuestions(state) {
    state.additional = getBaseAdditionalDetailState()
  },
  setReporterOptsIntoEmail(state, value) {
    state.reporterOptsIntoEmail = value
  },
  setReporterOptsIntoText(state, value) {
    state.reporterOptsIntoText = value
  },
  setDateOfLoss(state, value) {
    state.dateOfLoss = value
  },
  setDateOfNotification(state, value) {
    state.dateOfNotification = value
  },
  setNotificationMethod(state, value) {
    state.notificationMethod = value
  },
  setDescriptionOfLoss(state, value) {
    state.descriptionOfLoss = value
  },
  setCauseOfDamage(state, value) {
    state.causeOfDamage = value
  },
  setDamageType(state, value) {
    state.damageType = value
  },
  setSourceOfDamage(state, value) {
    state.sourceOfDamage = value
  },
  setDoesKnowSourceOfDamage(state, value) {
    state.doesKnowSourceOfDamage = value
  },
  setPropertyDamages(state, value) {
    state.propertyDamage.damages = value
  },
  setAnyUnfinishedDamagedRooms(state, value) {
    state.propertyDamage.anyUnfinishedDamagedRooms = value
  },
  setHasExteriorDamage(state, value) {
    state.propertyDamage.hasExteriorDamage = value
  },
  setNumberRoomsDamaged(state, value) {
    state.propertyDamage.numberRoomsDamaged = value
  },
  setNumberStructuresDamaged(state, value) {
    state.propertyDamage.numberStructuresDamaged = value
  },
  setNumberItemsDamaged(state, value) {
    state.propertyDamage.numberItemsDamaged = value
  },
  setDescriptionOfDamagedProperty(state, value) {
    state.propertyDamage.descriptionOfDamagedProperty = value
  },
  setIsImmediateAssistanceNeeded(state, value) {
    state.additional.isImmediateAssistanceNeeded = value
  },
  setHasHolesInDoorsWindows(state, value) {
    state.additional.hasHolesInDoorsWindows = value
  },
  setWasRoofDamaged(state, value) {
    state.additional.wasRoofDamaged = value
  },
  setHasResultingWaterDamage(state, value) {
    state.additional.hasResultingWaterDamage = value
  },
  setDidLosePower(state, value) {
    state.additional.didLosePower = value
  },
  setHasStandingWater(state, value) {
    state.additional.hasStandingWater = value
  },
  setWasHomeBrokenInto(state, value) {
    state.additional.wasHomeBrokenInto = value
  },
  setIsHomeSafe(state, value) {
    state.additional.isHomeSafe = value
  },
  setIsWaterTurnedOff(state, value) {
    state.additional.isWaterTurnedOff = value
  },
  setDidTurnoffStopWater(state, value) {
    state.additional.didTurnoffStopWater = value
  },
  setDidContactPlumber(state, value) {
    state.additional.didContactPlumber = value
  },
  setDidAdviseOnPlumber(state, value) {
    state.additional.didAdviseOnPlumber = value
  },
  setDidFireDepartmentCome(state, value) {
    state.additional.didFireDepartmentCome = value
  },
  setWerePoliceNotified(state, value) {
    state.additional.werePoliceNotified = value
  },
  setWasReportFiled(state, value) {
    state.additional.wasReportFiled = value
  },
  setWasArrestMade(state, value) {
    state.additional.wasArrestMade = value
  },
  setDidReceiveMedicalTreatment(state, value) {
    state.additional.didReceiveMedicalTreatment = value
  },
  setDescriptionOfTreatment(state, value) {
    state.additional.descriptionOfTreatment = value
  },
  setInjuredHasAttorney(state, value) {
    state.additional.injuredHasAttorney = value
  },
  setOwnerHasAttorney(state, value) {
    state.additional.ownerHasAttorney = value
  },
  setIsLawsuitFiled(state, value) {
    state.additional.isLawsuitFiled = value
  },
  setDidContactMitigation(state, value) {
    state.additional.didContactMitigation = value
  },
  setMitigationContactIds(state, value) {
    state.additional.mitigationContactIds = value
  },
  setHasPropertyOwnerInformation(state, value) {
    state.additional.hasPropertyOwnerInformation = value
  },
  setPropertyOwnerContactIds(state, value) {
    state.additional.propertyOwnerContactIds = value
  },
  setDidIncidentOccurAtProperty(state, value) {
    state.additional.didIncidentOccurAtProperty = value
  },
  setIncidentLocationDescription(state, value) {
    state.additional.incidentLocation.description = value
  },
  setIncidentLocationStreetLineOne(state, value) {
    state.additional.incidentLocation.streetLineOne = value
  },
  setIncidentLocationStreetLineTwo(state, value) {
    state.additional.incidentLocation.streetLineTwo = value
  },
  setIncidentLocationCity(state, value) {
    state.additional.incidentLocation.city = value
  },
  setIncidentLocationState(state, value) {
    state.additional.incidentLocation.region = value
  },
  setIncidentLocationZip(state, value) {
    state.additional.incidentLocation.postalCode = value
  },
  setHasInjuredPersonsInformation(state, value) {
    state.additional.hasInjuredPersonsInformation = value
  },
  setInjuredPersonContactIds(state, value) {
    state.additional.injuredPersonContactIds = value
  },
  setAttachmentLoading(state, file) {
    state.attachments.push(file)
  },
  setAttachmentStatus(state, payload) {
    const fileToUpdate = state.attachments.find(file => file.id === payload.id)
    fileToUpdate.status = payload.status
  },
  setAttachmentObjectName(state, payload) {
    const fileToUpdate = state.attachments.find(file => file.id === payload.id)
    fileToUpdate.objectName = payload.objectName
  },
  removeAttachment(state, id) {
    state.attachments = state.attachments.filter((file) => {
      return file.id !== id
    })
  },
  clearAttachments(state) {
    state.attachments = []
  },
  setContactsTabHasErrors(state, value) {
    state.contactsTabHasErrors = value
  },
  setDamageTabHasErrors(state, value) {
    state.damageTabHasErrors = value
  },
  setHasVisitedDamageTab(state, value) {
    state.hasVisitedDamageTab = value
  },
  setHasAttemptedReviewScreen(state, value) {
    state.hasAttemptedReviewScreen = value
  },
  setClaimNumber(state, value) {
    state.claimNumber = value
  },
  setClaimReferenceNumber(state, value) {
    state.claimReferenceNumber = value
  },
  resetGrpicUuid(state) {
    state.grpcUuid = uuidv4()
  },
}
