export const state = () => ({ queue: [] })

// This will be fleshed out more as we find errors that we want to store in state
export const mutations = {
  displayErrorModal(state, props) {
    const modalType = 'ModalError'
    const existingError = state.queue.find((error) => {
      return props?.title === error.title &&
        props?.message === error.message &&
        props?.showOk === error.showOk &&
        error.component === modalType
    })

    // Only add to the queue if the title or error message is different.
    // This is a temporary solution to help reduce the number of times
    // an agent has to click through an error modal that is not unique.
    // This will be made more robust in the future as more error types
    // get specific messaging or significant overlap begins to occur.
    if (!existingError) {
      return state.queue.push({
        component: modalType,
        title: props?.title,
        message: props?.message,
        showOk: props?.showOk,
        onCloseCallback: props?.onClose,
      })
    }

    // There is no new functionality to wrap, do nothing
    if (!props?.onClose) {
      return
    }

    // Wrap the functionality together and call the previous callback if it
    // exists along with the new callback.
    const existingCallback = existingError.onCloseCallback
    existingError.onCloseCallback = () => {
      existingCallback?.()
      props.onClose()
    }
  },

  dequeError(state) {
    // Remove the first item from the error stack
    state.queue.shift()
  },

  reset(state) {
    state.queue = []
  },
}
