import { POLICY_NOT_FOUND, POLICY_NOT_ISSUED } from '~/apollo/error-codes'

function isIDError(response) {
  const code = response.gqlError?.extensions?.code
  if (!code) { return { valid: false } }

  return {
    valid: code === POLICY_NOT_FOUND,
    redirect: true,
    errorContext: {
      message: 'The policy you\'re trying to reach does not exist.',
    },
  }
}

function isPolicyIsNotIssuedError(response, config, params) {
  const code = response.gqlError?.extensions?.code
  if (!code) { return { valid: false } }

  return {
    valid: code === POLICY_NOT_ISSUED,
    redirect: true,
    errorContext: {
      message: 'The policy you\'re trying to reach is not issued.',
      buttonUrl: `${config.legacyPortalDomain}/agents/dashboard?number=${params.number}`,
      buttonText: 'Return to Policy',
    },
  }
}

function isFromCore(response) {
  const source = response.gqlError?.extensions?.source
  if (!source) { return { valid: false } }

  return {
    valid: source === 'CORE',
    redirect: false,
  }
}

export default [
  isIDError,
  isPolicyIsNotIssuedError,
  // This must go below all core errors that we want a specific response for
  isFromCore,
]
