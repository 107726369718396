export default {
  shouldShowDamageType(state) {
    const causeOfDamageWithoutDamageTypes = ['equipmentBreakdown', 'vandalism', 'theft']
    return !causeOfDamageWithoutDamageTypes.includes(state.causeOfDamage)
  },
  shouldShowSourceOfDamage(state) {
    const damageTypesWithSourceQuestion = ['flooding', 'waterDamage', 'fire', 'smoke']
    return damageTypesWithSourceQuestion.includes(state.damageType)
  },
  sourceOfDamageIsComplete(state, getters) {
    return getters.shouldShowSourceOfDamage
      ? state.doesKnowSourceOfDamage === false || Boolean(state.sourceOfDamage)
      : true
  },
  shouldShowWhatWasDamaged(state, getters) {
    const damageTypesWithoutProperyDamage = ['dogBite', 'slipFall', 'otherInjury']
    const damageTypeNeedsPropertyDamage = !damageTypesWithoutProperyDamage.includes(state.damageType)

    if (!state.causeOfDamage) {
      return false
    }

    if (getters.shouldShowDamageType && !state.damageType) {
      return false
    }

    return damageTypeNeedsPropertyDamage && getters.sourceOfDamageIsComplete
  },
  shouldShowDescriptionOfDamagedProperty(state) { return state.damageType === 'thirdPartyPropertyDamage' },
  hasHomeStructureDamage(state) { return state.propertyDamage.damages.includes('homeStructure') },
  hasContentsOrPersonalPropertyDamage(state) { return state.propertyDamage.damages.includes('contentsOrPersonalProperty') },
  hasOtherStructuresDamage(state) { return state.propertyDamage.damages.includes('otherStructures') },
  shouldShowUnfinishedRoomQuestion(state) {
    return state.propertyDamage.numberRoomsDamaged !== null && state.propertyDamage.numberRoomsDamaged !== 'none'
  },

  // Additional Question Logic
  shouldShowAdditionalQuestionSection(state, getters) {
    return getters.shouldShowDamageType ? Boolean(state.damageType) : Boolean(state.causeOfDamage)
  },
  shouldShowImmediateAssistanceQuestion(state) { return state.causeOfDamage === 'fire' || state.causeOfDamage === 'other' || state.causeOfDamage === 'equipmentBreakdown' },
  shouldShowHolesQuestion(state) { return state.causeOfDamage === 'weather' || state.damageType === 'rain' },
  shouldShowRoofDamageQuestion(state) {
    const otherDamageTypesWithRoofQuestion = ['collapse', 'collision', 'earthquake', 'explosion', 'fallingObject', 'sinkhole', 'other', 'rain']
    return state.causeOfDamage === 'weather' || state.causeOfDamage === 'fire' || otherDamageTypesWithRoofQuestion.includes(state.damageType)
  },
  shouldShowResultingWaterDamageQuestion(state) { return state.causeOfDamage === 'weather' || state.damageType === 'rain' },
  shouldShowLosePowerQuestion(state) { return state.causeOfDamage === 'weather' || state.damageType === 'rain' },
  shouldShowStandingWaterQuestion(state) { return state.causeOfDamage === 'water' && state.damageType !== 'rain' },
  shouldShowHomeBrokenIntoQuestion(state) { return state.causeOfDamage === 'theft' || state.causeOfDamage === 'vandalism' },
  shouldShowHomeSafeQuestion(state) { return state.causeOfDamage !== 'injuryOrLiability' },
  shouldShowWaterTurnoffQuestion(state) { return state.causeOfDamage === 'water' && state.damageType !== 'rain' },
  shouldShowFireDeptQuestion(state) { return state.causeOfDamage === 'fire' },
  shouldShowInjuryAttorneyQuestion(state) { return state.causeOfDamage === 'injuryOrLiability' && state.damageType !== 'thirdPartyPropertyDamage' },
  shouldShowOwnerAttorneyQuestion(state) { return state.damageType === 'thirdPartyPropertyDamage' },
  shouldShowPoliceWithReportQuestion(state) { return state.causeOfDamage === 'injuryOrLiability' },
  shouldShowPoliceWithArrestQuestion(state) { return state.causeOfDamage === 'theft' || state.causeOfDamage === 'vandalism' },
  shouldShowMedicalTreatmentQuestion(state) { return state.damageType === 'dogBite' || state.damageType === 'slipFall' || state.damageType === 'otherInjury' },
  shouldShowMitigationQuestion(state) { return state.causeOfDamage && state.causeOfDamage !== 'injuryOrLiability' },
  shouldShowPropertyOwnerQuestion(state) { return state.damageType === 'thirdPartyPropertyDamage' },
  shouldShowIncidentLocationQuestion(state) { return state.causeOfDamage === 'injuryOrLiability' || state.causeOfDamage === 'theft' || state.causeOfDamage === 'vandalism' },
  shouldShowInjuredPersonInfoQuestion(state) { return state.damageType === 'dogBite' || state.damageType === 'slipFall' || state.damageType === 'otherInjury' },
  // End Additional Question Logic

  // FNOL Tabs Logic
  shouldShowContactTabWarning(state) { return state.contactsTabHasErrors && state.hasVisitedDamageTab },
  shouldShowContactTabSuccess(state) { return !state.contactsTabHasErrors && state.hasVisitedDamageTab },
  shouldShowDamageTabWarning(state) { return state.damageTabHasErrors && state.hasVisitedDamageTab && state.hasAttemptedReviewScreen },
  shouldShowDamageTabSuccess(state) { return !state.damageTabHasErrors && state.hasAttemptedReviewScreen },
  canAccessReviewTab(state) { return !state.contactsTabHasErrors && !state.damageTabHasErrors },
  // End Tab Logic
}
