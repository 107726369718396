//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import FEATURE_FLAGS from '~/graphql/queries/featureFlags.gql'

export default {
  data() {
    return {
      sidebarCollapsed: false,
      featureFlags: null,
    }
  },
  computed: {
    FEATURE_FLAG_IS_RENEWALS_DASHBOARD_ENABLED() {
      return Boolean(this.featureFlags?.['portal-admin-is-renewals-dashboard-enabled'])
    },
    FEATURE_FLAG_IS_NON_RENEWALS_DASHBOARD_ENABLED() {
      return Boolean(this.featureFlags?.['portal-is-non-renewals-dashboard-enabled'])
    },
    FEATURE_FLAG_IS_NEW_SEARCH_ENABLED() {
      return Boolean(this.featureFlags?.['ax-efficiency-is-new-search-enabled'])
    },
    navItems() {
      const items = [
        { to: '/eligible-quotes', title: 'Quotes', icon: 'file-contract', disabled: false },
      ]

      if (this.FEATURE_FLAG_IS_RENEWALS_DASHBOARD_ENABLED) {
        items.push({
          to: '/upcoming-renewals',
          title: 'Renewals',
          icon: 'arrows-retweet',
          disabled: false,
        })
      }

      if (this.FEATURE_FLAG_IS_NON_RENEWALS_DASHBOARD_ENABLED) {
        items.push({
          to: '/non-renewals',
          title: 'Non-Renewals',
          icon: 'ban',
          disabled: false,
        })
      }

      if (this.FEATURE_FLAG_IS_NEW_SEARCH_ENABLED) {
        items.unshift({
          to: '/search',
          title: 'Search',
          icon: 'magnifying-glass',
          disabled: false,
        })
      }

      return items
    },
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.sidebarCollapsed = true
    }
  },
  methods: {
    ...mapActions('self', ['logout']),
    async openAgencyResources() {
      await window.analytics.track('Agency Resources Click')
      window.open('https://help.openly.com/hc/en-us/articles/15532386227479-Agent-Resources', '_blank', 'noopener,noreferrer')
    },
    openHelpCenter() {
      window.open('https://help.openly.com/hc/en-us', '_blank')
    },
    trackSidebarClick(linkTitle) {
      window.analytics.track('Sidebar Menu Click', {
        LinkClicked: linkTitle,
      })
    },
  },
  apollo: {
    featureFlags() {
      return {
        query: FEATURE_FLAGS,
        fetchPolicy: 'cache-and-network',
      }
    },
  },
}
