//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { KudoButton } from '@openly-engineering/kudo'
import { mapState } from 'vuex'
import SELF from '~/graphql/queries/self.gql'

export default {
  components: {
    KudoButton,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: mapState({
    avatarUrl: state => state.self.avatarUrl,
  }),
  apollo: {
    self() {
      return {
        query: SELF,
      }
    },
  },
  mounted() {
    window.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)) {
        this.isOpen = false
      }
    })
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen
    },
  },
}
