//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import ModalErrorController from '~/components/modal/modal-error-controller'

export default {
  name: 'Default',
  components: {
    ModalErrorController,
  },
  computed: {
    envTitle() {
      switch (this.$config?.vercelENV) {
        case 'development':
          return 'DEVELOPMENT'
        case 'preview':
          return 'STAGING'
        default:
          return null
      }
    },
    ...mapState({
      isAuthenticated: state => state.self.isAuthenticated,
    }),
  },
  watch: {
    $route: {
      handler() {
        this.$segment.sendPageEvent()
      },
      immediate: false,
    },
  },
}
