export default ({ $config }, inject) => {
  const chat = {
    setupChat: (user, zendeskChatJWT) => {
      const script = document.createElement('script')
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${$config.zendeskWidgetKey}`
      script.id = 'ze-snippet'

      script.onload = () => {
        if (window.zE) {
          window.zESettings = {
            disabled: true,
            webWidget: {
              chat: {
                title: {
                  '*': 'Live Chat',
                },
              },
              contactForm: {
                suppress: false,
                attachments: false,
              },
              helpCenter: {
                suppress: true,
              },
              talk: {
                suppress: true,
              },
              answerBot: {
                suppress: true,
              },
              offset: {
                horizontal: '10px',
                vertical: '10px',
              },
              authenticate: {
                chat: {
                  jwtFn: callback => callback(zendeskChatJWT),
                },
              },
            },
          }

          // pre-populate contact request form, if chat is unavailable
          window.zE('webWidget', 'prefill', {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
          })

          // hack to hide the little lower-right-corner launcher badge
          window.zE('webWidget', 'hide')

          window.zE('webWidget:on', 'open', () => {
            window.zE('webWidget', 'show')
          })

          window.zE('webWidget:on', 'close', () => {
            window.zE('webWidget', 'hide')
          })

          // open the widget if we receive any unread messages
          window.zE('webWidget:on', 'chat:unreadMessages', () => {
            window.zE('webWidget', 'open')
          })
        }
      }

      document.head.appendChild(script)
    },
    openChat: () => {
      window.zE('webWidget', 'open')
    },
  }

  inject('chat', chat)
}
