import Axios from 'axios'
import { saveAs } from 'file-saver'

class DocumentsAPI {
  constructor(tokenGetter, baseURL) {
    const axiosOptions = {
      'Cache-Control': 'no-cache',
    }

    if (baseURL) {
      axiosOptions.baseURL = baseURL
    }

    this._api = Axios.create(axiosOptions)
    this._tokenGetter = tokenGetter
  }

  get _bearerToken() {
    return this._tokenGetter()
  }

  async uploadDocument(document, documentType, policyNumber, termID, transactionID) {
    const fd = new FormData()
    fd.append('document', document)
    fd.append('documentType', documentType)

    let url = '/upload'

    if (
      documentType === 'CCAuthorization' ||
      documentType === 'EFTAuthorization'
    ) {
      url = '/secure-upload'
    }

    url = `${url}/policy/${policyNumber}/term/${termID}/transaction/${transactionID}`

    return await this._api({
      method: 'post',
      url,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this._bearerToken}`,
      },
      data: fd,
    })
  }

  async getDocumentBlob(filePath) {
    const { data } = await this._api({
      method: 'get',
      url: `/download/${filePath}`,
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${this._bearerToken}`,
      },
    })
    return new Blob([data])
  }

  async getDocumentFile(filePath, fileName) {
    const documentBlob = await this.getDocumentBlob(filePath)
    return new File([documentBlob], fileName)
  }

  async downloadDocument(filePath, fileName) {
    const file = await this.getDocumentFile(filePath, fileName)
    await saveAs(file)
  }

  async uploadDraftDocument(document, documentType, policyNumber, termID, transactionID) {
    const fd = new FormData()
    fd.append('document', document)
    fd.append('documentType', documentType)

    const url = `/upload-draft-doc/policy/${policyNumber}/term/${termID}/transaction/${transactionID}`

    return await this._api({
      method: 'post',
      url,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this._bearerToken}`,
      },
      data: fd,
    })
  }

  async searchDraftDocuments(policyNumber, termID, transactionID) {
    const url = `/search-draft-docs/policy/${policyNumber}/term/${termID}/transaction/${transactionID}`

    return await this._api({
      method: 'get',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this._bearerToken}`,
      },
    })
  }

  async deleteDraftDocument(documentID, policyNumber, termID, transactionID) {
    const url = `/delete-draft-doc/policy/${policyNumber}/term/${termID}/transaction/${transactionID}/draftDocs/${documentID}`

    return await this._api({
      method: 'delete',
      url,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this._bearerToken}`,
      },
    })
  }

  async getDraftDocument(documentID, policyNumber, termID, transactionID) {
    const { data } = await this._api({
      method: 'get',
      url: `/get-draft-doc/policy/${policyNumber}/term/${termID}/transaction/${transactionID}/draftDocs/${documentID}`,
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${this._bearerToken}`,
      },
    })

    const documentBlob = new Blob([data])
    return new File([documentBlob], 'cancellation')
  }
}

export default ({ $apolloHelpers, $config }, inject) => {
  inject('documentsAPI', new DocumentsAPI(() => $apolloHelpers.getToken(), $config.documentsEndpoint))
}
